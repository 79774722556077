import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {researchUtils_ReloadResults, researchUtils_RenderResults} from "../utils/research.utils";

const useStyles = makeStyles({
	root: {
		position: "fixed",
		top: 0,
		right: 0,
		backgroundColor: "#d7f2ec",
		width: 320,
		padding: 24,
		boxSizing: "border-box",
		overflow: "scroll",
		zIndex: 101,
		height: "95%",
		opacity: 0.8
	},
	result: {
		"& h4": {
			fontSize: 12,
			fontWeight: "normal"
		},
		"& h4.success": {
			color: "green"
		},
		"& h4.error": {
			color: "red"
		},
		"& .reason": {
			fontSize: 11, fontWeight: "normal"
		}
	},
	summary: {
		fontSize: 13,
		marginTop: 16,
		"& .passed": {
			color: "green"
		},
		"& .failed": {
			color: "red"
		}
	}
});

export default function FilterResultTest(props) {
	const classes = useStyles();
	const show = props.rid === 16 && localStorage.getItem("test") === "417";
	let testIndex = 0;
	const testSettings =
		[{
			filter: [...Array(13).keys()],
			expected: {
				1: "BikeroANO93.7%zodpovědíKoloshopCZNE65.9%zodpovědíMojekoloNE66.7%zodpovědíKupkoloczNE73.8%zodpovědí",
				3: "BikeroMODERNÍ21.2%zodpovědíKoloshopCZPROFESIONÁLNÍ14.7%zodpovědíMojekoloSYMPATICKÁ15.2%zodpovědíKupkoloczSPOLEHLIVÁ20.3%zodpovědí",
				8: "BikeroDRAHÁ70.9%zodpovědíNEPŘÍJEMNÁ27.0%zodpovědíNEVÍMCOPRODÁVAJÍ23.3%zodpovědíKoloshopCZPŘÍJEMNÁ88.4%zodpovědíSPOLEHLIVÁ88.1%zodpovědíKVALITNÍ81.4%zodpovědíVÍMCOPRODÁVAJÍ80.5%zodpovědíMojekoloKupkoloczLEVNÁ69.0%zodpovědíNEKVALITNÍ34.4%zodpovědíNESPOLEHLIVÁ24.2%zodpovědí",
				2: "BikeroNIKDY51.7%zodpovědíKoloshopCZNIKDY37.2%zodpovědíMojekoloNIKDY50.0%zodpovědíKupkoloczNIKDY42.4%zodpovědí",
				5: "BikeroCENYJSOUNÍZKÉ19.0%zodpovědíKoloshopCZCENYJSOUNÍZKÉ26.7%zodpovědíMojekoloCENYJSOUNÍZKÉ27.0%zodpovědíKupkoloczCENYJSOUNÍZKÉ31.7%zodpovědí",
				13: "BikeroANO74.6%zodpovědíKoloshopCZNE69.8%zodpovědíMojekoloNE73.8%zodpovědíKupkoloczNE72.7%zodpovědí",
				7: "BikeroKOLA/CYKLISTIKA/PRODEJKOL(OBECNĚ)18.3%zodpovědíKoloshopCZKOLA/CYKLISTIKA/PRODEJKOL(OBECNĚ)13.2%zodpovědíMojekoloKOLA/CYKLISTIKA/PRODEJKOL(OBECNĚ)13.1%zodpovědíKupkoloczPOZITIVNÍDOJMY20.9%zodpovědí",
				14: "BikeroVTELEVIZI52.6%zodpovědíKoloshopCZNAINTERNETU(VYHLEDÁVÁNÍ)31.3%zodpovědíMojekoloNAINTERNETU(VYHLEDÁVÁNÍ)31.3%zodpovědíKupkoloczNAINTERNETU(VYHLEDÁVÁNÍ)35.7%zodpovědí",
				6: "BikeroJEŠTĚJSEMTAMNENAKOUPIL/A58.5%zodpovědíKoloshopCZJEŠTĚJSEMTAMNENAKOUPIL/A41.9%zodpovědíMojekoloJEŠTĚJSEMTAMNENAKOUPIL/A52.4%zodpovědíKupkoloczJEŠTĚJSEMTAMNENAKOUPIL/A45.5%zodpovědí",
				22: "BikeroVTELEVIZI/REKLAMAVTV55.6%zodpovědíKoloshopCZNAINTERNETU/REKLAMANAINTERNETU32.0%zodpovědíMojekoloNAINTERNETU/REKLAMANAINTERNETU53.8%zodpovědíKupkoloczNAINTERNETU/REKLAMANAINTERNETU46.7%zodpovědí"
			}
		},
		{
			filter: [1,2,3,4,5,6,7,8,9,10,11,12],
			expected: {
				1: "BikeroANO92.1%zodpovědíKoloshopCZNE58.7%zodpovědíMojekoloNE57.1%zodpovědíKupkoloczNE66.7%zodpovědí",
				3: "BikeroMODERNÍ20.2%zodpovědíKoloshopCZPROFESIONÁLNÍ17.5%zodpovědíMojekoloZAVEDENÁ17.5%zodpovědíKupkoloczSPOLEHLIVÁ17.1%zodpovědí",
				8: "BikeroDRAHÁ63.0%zodpovědíNEPŘÍJEMNÁ25.9%zodpovědíNESPOLEHLIVÁ24.6%zodpovědíNEVÍMCOPRODÁVAJÍ17.5%zodpovědíKoloshopCZMojekoloSPOLEHLIVÁ92.3%zodpovědíKupkoloczVÍMCOPRODÁVAJÍ90.0%zodpovědíPŘÍJEMNÁ76.2%zodpovědíLEVNÁ72.2%zodpovědíKVALITNÍ70.0%zodpovědíNEKVALITNÍ30.0%zodpovědí",
				2: "BikeroNIKDY50.0%zodpovědíKoloshopCZNIKDY30.8%zodpovědíMojekoloNIKDY37.0%zodpovědíKupkoloczNIKDY38.1%zodpovědí",
				5: "BikeroCENYJSOUNÍZKÉ20.0%zodpovědíKoloshopCZCENYJSOUNÍZKÉ25.0%zodpovědíMojekoloCENYJSOUNÍZKÉ28.3%zodpovědíKupkoloczCENYJSOUNÍZKÉ31.1%zodpovědí",
				13: "BikeroANO72.4%zodpovědíKoloshopCZNE73.1%zodpovědíMojekoloNE66.7%zodpovědíKupkoloczNE66.7%zodpovědí",
				7: "BikeroKOLA/CYKLISTIKA/PRODEJKOL(OBECNĚ)19.9%zodpovědíKoloshopCZNEVÍM11.6%zodpovědíMojekoloKOLA/CYKLISTIKA/PRODEJKOL(OBECNĚ)10.8%zodpovědíKupkoloczPOZITIVNÍDOJMY26.4%zodpovědí",
				14: "BikeroVTELEVIZI50.8%zodpovědíKoloshopCZNAINTERNETU(VYHLEDÁVÁNÍ)37.5%zodpovědíMojekoloNAINTERNETU(VYHLEDÁVÁNÍ)28.6%zodpovědíKupkoloczNAINTERNETU(VYHLEDÁVÁNÍ)40.0%zodpovědí",
				6: "BikeroJEŠTĚJSEMTAMNENAKOUPIL/A56.9%zodpovědíKoloshopCZJEŠTĚJSEMTAMNENAKOUPIL/A34.6%zodpovědíMojekoloJEŠTĚJSEMTAMNENAKOUPIL/A37.0%zodpovědíKupkoloczJEŠTĚJSEMTAMNENAKOUPIL/A38.1%zodpovědí",
				22: "BikeroVTELEVIZI/REKLAMAVTV58.6%zodpovědíKoloshopCZNAINTERNETU/REKLAMANAINTERNETU62.5%zodpovědíMojekoloNAINTERNETU/REKLAMANAINTERNETU45.5%zodpovědíKupkoloczNAINTERNETU/REKLAMANAINTERNETU38.5%zodpovědí"
			}
		},
		{
			filter: [0,1,2,3,6,7,8,9,10,11,12],
			expected: {
				1: "BikeroANO91.5%zodpovědíKoloshopCZNE59.6%zodpovědíMojekoloNE61.7%zodpovědíKupkoloczNE68.1%zodpovědí",
				3: "BikeroMODERNÍ20.6%zodpovědíKoloshopCZZAVEDENÁ18.6%zodpovědíMojekoloSPOLEHLIVÁ16.2%zodpovědíKupkoloczSPOLEHLIVÁ18.8%zodpovědí",
				8: "BikeroDRAHÁ66.7%zodpovědíKoloshopCZPŘÍJEMNÁ100.0%zodpovědíSPOLEHLIVÁ94.4%zodpovědíKVALITNÍ84.2%zodpovědíVÍMCOPRODÁVAJÍ77.8%zodpovědíMojekoloLEVNÁ86.7%zodpovědíNEVÍMCOPRODÁVAJÍ31.3%zodpovědíKupkoloczNEKVALITNÍ40.0%zodpovědíNEPŘÍJEMNÁ26.7%zodpovědíNESPOLEHLIVÁ26.7%zodpovědí",
				2: "BikeroNIKDY55.8%zodpovědíKoloshopCZASI1XAŽ2XZAROK36.8%zodpovědíMojekoloNIKDY50.0%zodpovědíKupkoloczNIKDY40.0%zodpovědí",
				5: "BikeroCENYJSOUNÍZKÉ23.2%zodpovědíKoloshopCZCENYJSOUNÍZKÉ28.2%zodpovědíMojekoloCENYJSOUNÍZKÉ29.4%zodpovědíKupkoloczCENYJSOUNÍZKÉ33.3%zodpovědí",
				13: "BikeroANO79.1%zodpovědíKoloshopCZNE68.4%zodpovědíMojekoloNE77.8%zodpovědíKupkoloczNE80.0%zodpovědí",
				7: "BikeroREKLAMA20.0%zodpovědíKoloshopCZNEVÍM15.2%zodpovědíMojekoloKOLA/CYKLISTIKA/PRODEJKOL(OBECNĚ)14.3%zodpovědíKupkoloczKOLA/CYKLISTIKA/PRODEJKOL(OBECNĚ)19.0%zodpovědí",
				14: "BikeroVTELEVIZI59.2%zodpovědíKoloshopCZNAINTERNETU(VYHLEDÁVÁNÍ)33.3%zodpovědíMojekoloNAINTERNETU(VYHLEDÁVÁNÍ)33.3%zodpovědíKupkoloczNAINTERNETU(VYHLEDÁVÁNÍ)100.0%zodpovědí",
				6: "BikeroJEŠTĚJSEMTAMNENAKOUPIL/A58.1%zodpovědíKoloshopCZJEŠTĚJSEMTAMNENAKOUPIL/A42.1%zodpovědíMojekoloJEŠTĚJSEMTAMNENAKOUPIL/A55.6%zodpovědíKupkoloczJEŠTĚJSEMTAMNENAKOUPIL/A40.0%zodpovědí",
				22: "BikeroVTELEVIZI/REKLAMAVTV54.4%zodpovědíKoloshopCZNAINTERNETU/REKLAMANAINTERNETU16.7%zodpovědíMojekoloNASOCIÁLNÍCHSÍTÍCH/REKLAMANASOCIÁLNÍCHSÍTÍCH66.7%zodpovědíKupkoloczNAINTERNETU/REKLAMANAINTERNETU22.2%zodpovědí"
			}
		}];

	function reloadData()
	{
		researchUtils_ReloadResults(function (data){
			researchUtils_RenderResults(data);

			const resultsContainer = document.getElementById("test_result");
			const questions = document.querySelectorAll(".question");
			const expected = testSettings[testIndex].expected;
			let qr, qid, err;

			if (testIndex === 0)
				resultsContainer.innerText = "";

			resultsContainer.appendChild(document.createElement('hr'));

			for (let i in questions)
			{
				if (questions.hasOwnProperty(i))
				{
					qr = document.createElement('h4');
					err = document.createElement('div');

					qr.innerText = questions[i].innerText;
					qr.className = "error";

					err.className = "reason";

					resultsContainer.appendChild(qr);
					resultsContainer.appendChild(err);

					qid = parseInt(questions[i].nextSibling.nextSibling.nextSibling.nextSibling.id.split("_")[1]);

					if (isNaN(qid))
					{
						err.innerText = "Question ID not found.";
						addFailed();
					}
					else if (expected[qid] === undefined)
					{
						err.innerText = "Expected value not defined.";
						addFailed();
					}
					else if (questions[i].nextSibling.nextSibling.innerText.replace(/[\s]/g, '').trim() !== expected[qid])
					{
						err.innerHTML = "Expected: " + expected[qid] + "<br/>Get: " + questions[i].nextSibling.nextSibling.innerText.replace(/[\s]/g, '').trim();
						addFailed();
					}
					else
					{
						err.innerText = 'ok';
						qr.className = "success";

						addPassed();
					}
				}
			}

			testIndex++;
			runTest();
		});
	}

	function setFilter(f)
	{
		const chcks = document.querySelectorAll(".filter-checkbox input[type='checkbox']");

		for (let i in chcks)
		{
			if (chcks.hasOwnProperty(i))
			{
				if (chcks[i].checked && f.indexOf(parseInt(i)) === -1)
				{
					chcks[i].click();
				}

				if (!chcks[i].checked && f.indexOf(parseInt(i)) > -1)
				{
					chcks[i].click();
				}
			}
		}
	}

	function runTest()
	{
		if (testSettings[testIndex] === undefined)
		{
			const el = document.getElementById("testing_layer");
			el.parentNode.removeChild(el);

			return;
		}


		if (testIndex === 0)
		{
			const layer = document.createElement('div');

			layer.style.width = "100%";
			layer.style.height = "100%";
			layer.style.position = "fixed";
			layer.style.top = "0";
			layer.style.left = "0";
			layer.style.backgroundColor = "rgba(0,0,0,0.5)";
			layer.style.zIndex = "100";
			layer.style.padding = "32px";

			layer.innerText = "Testing ..."
			layer.id = "testing_layer";

			document.body.appendChild(layer);

			document.getElementById("tests_passed").innerText = "0";
			document.getElementById("tests_failed").innerText = "0";
		}

		setFilter(testSettings[testIndex].filter);
		reloadData();
	}

	function addPassed()
	{
		const el = document.getElementById("tests_passed");
		el.innerText = (parseInt(el.innerText) + 1).toString();
	}

	function addFailed()
	{
		const el = document.getElementById("tests_failed");
		el.innerText = (parseInt(el.innerText) + 1).toString();
	}

	return (
		<div>
		{show &&
		<div className={classes.root}>
			<button type="button" onClick={runTest}>Run tests</button>
			<div className={classes.summary}>
				PASSED:&nbsp;<span id="tests_passed" className="passed">0</span>&nbsp;&nbsp;&nbsp;FAILED:&nbsp;<span id="tests_failed" className="failed">0</span>
			</div>
			<div className={classes.result} id="test_result"> </div>
		</div>}
		</div>
	);
}
